<template>
  <AppTextField v-model="inputValue" ref="inputRef" :reverse="reverse" @focus="handleSelectText()" />
</template>

<script>

import { ref, watch, onMounted } from "vue";
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",

  props: {
    modelValue: {},
    precision: {
      type: Number,
      default: 2,
    },
    acceptNull: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: true,
    },
    acceptNegative: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 999999999,
    },

  },

  setup(props, { emit }) {

    const inputValue = ref();

    const options = ref({
      locale: "pt-Br",
      currency: "EUR",
      currencyDisplay: "hidden",
      precision: props.precision,
      autoDecimalDigits: true,
      hideGroupingSeparatorOnFocus: false,
      valueRange: {
        min: props.acceptNegative ? -999999999 : 0,
        max: props.max,
      },
    });

    const { inputRef, setOptions, setValue, formattedValue, numberValue } = useCurrencyInput(options.value, false);

    onMounted(async () => {
      // do nothing
    });

    watch(() => props.precision, (value) => {

      options.value.precision = value;
      setOptions(options.value);
    });

    watch(() => props.modelValue, (value) => {
      setValue(value);
      inputValue.value = formattedValue.value;
    });

    watch(() => numberValue.value, (value) => {

      if (value === null && !props.acceptNull) {
        setValue(0);
        inputValue.value = formattedValue.value;
        return;
      }

      inputValue.value = formattedValue.value;
      emit("update:modelValue", value);
    });

    const handleSelectText = () => {
      inputRef.value.select();
    };

    return { inputRef, inputValue, handleSelectText };
  },
};
</script>
